import {
  Button,
  TextField,
  FormControl,
  Divider,
  InputLabel,
  Select,
  MenuItem,
  TableContainer,
  Paper,
  Toolbar,
} from "@mui/material";
import { useContext, useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import useApiRequest from "../../store/use-http";
import PaymentMethodEnum from "../../enum/PaymentMethodEnum";
import AuthContext from "../../store/auth-context";
import { useNavigate } from "react-router-dom";
import { isNull } from "../../utils/Utils";
import QuoteItems from "./QuoteItems";
import { baseUrl } from "../../Config";
import "./QuotePage.css";
import LanguageEnum from "../../enum/LanguageEnum";

const formStyle = {
  boxShadow: "0px 13px 20px 0px #80808029",
  display: "flex",
  flexDirection: "column",
};

const FormContent = (props) => {
  const paymentMethods = Object.keys(PaymentMethodEnum);
  const user = JSON.parse(sessionStorage.getItem("user"));
  const companyInfo = JSON.parse(sessionStorage.getItem("companyInfo"));
  const customers = props.customers;
  const items = props.items;

  const navigate = useNavigate();

  const [quote, setQuote] = useState(props.quote);
  const [customer, setCustomer] = useState(null);
  const [quoteNumber, setQuoteNumber] = useState(null);
  const [quoteDate, setQuoteDate] = useState(null);
  const [dueDate, setDueDate] = useState(null);
  const [deliveryDate, setDeliveryDate] = useState(null);
  const [paymentMethod, setPaymentMethod] = useState(null);
  const [quoteTime, setQuoteTime] = useState("09:00");
  const [quoteItems, setQuoteItems] = useState([]);
  const [calculations, setCalculations] = useState();
  const [notes, setNotes] = useState("");
  const [termsAndConditions, setTermsAndConditions] = useState(
    ""
  );

  useEffect(() => {
    if (!isNull(props.quote)) {
      setQuote(props.quote);
      setQuoteNumber(props.quote.quoteNumber);
      setQuoteDate(props.quote.quoteDate);
      setDueDate(props.quote.dueDate);
      setDeliveryDate(props.quote.deliveryDate);
      setPaymentMethod(props.quote.paymentMethod);
      setCustomer(props.quote.customer);
      setQuoteItems(props.quote.quoteItems);
      setNotes(props.quote.note);
      setTermsAndConditions(props.quote.termsAndConditions)
    }
  }, [props.quote]);

  const authContext = useContext(AuthContext);

  const handleItemAdded = (items) => {
    setQuoteItems(items);
  };

  const handleCaluclationsChange = (calculations) => {
    setCalculations(calculations);
  };

  const handleClientSelect = (event) => {
    const client = customers.find((c) => c.id === event.target.value);
    setCustomer(client);
    if(LanguageEnum[client.language] === LanguageEnum.ENG) {
      setNotes(companyInfo.notesEng);
      setTermsAndConditions(companyInfo.termsAndConditionsEng);
    } else {
      setNotes(companyInfo.defaultNotes);
      setTermsAndConditions(companyInfo.defaultTermsAndConditions);
    }
  };

  const hadleOnSaveClick = () => {
    const newQuote = {
      id: props.quote.id,
      customer: customer,
      quoteNumber: quoteNumber,
      quoteDate: quoteDate,
      dueDate: dueDate,
      seller: user.firstName + " " + user.lastName,
      quoteTime: quoteTime,
      deliveryDate: deliveryDate,
      paymentMethod: paymentMethod,
      currency: customer.currency,
      quoteItems: quoteItems,
      subtotal: calculations.subtotal,
      subtotalLocalCurrency: calculations.subtotalLcy,
      subtotalHrk: calculations.subtotalHrk,
      totalTax: calculations.tax,
      totalTaxLocalCurrency: calculations.taxLcy,
      totalTaxHrk: calculations.taxHrk,
      total: calculations.subtotal + calculations.tax,
      totalLocalCurrency: calculations.subtotalLcy + calculations.taxLcy,
      totalHrk: calculations.subtotalHrk + calculations.taxHrk,
      note: notes,
      termsAndConditions: termsAndConditions,
      createdOn: props.quote.createdOn,
      updatedOn: props.quote.updatedOn
    };
    if (isNull(newQuote.id)) {
      saveHandler(newQuote, "POST");
    } else {
      saveHandler(newQuote, "PUT");
    }
  };

  const saveHandler = async (quote, method) => {
    const url = baseUrl + "/api/quote";
    fetch(url, {
      method: method,
      body: JSON.stringify(quote),
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        Authorization: authContext.token,
      },
    })
      .then((res) => {
        if (res.ok) {
          return res.json();
        } else {
          return res.json().then((data) => {
            let alert = "Failed to save the quote";
            throw new Error(alert);
          });
        }
      })
      .then((data) => {
        navigate("/quotes/preview/" + data.id);
      })
      .catch((err) => console.log(err.message));
  };

  return (
    <>
      <TableContainer component={Paper} style={formStyle}>
        <Toolbar>
          <Button variant="contained" onClick={hadleOnSaveClick}>
            Save
          </Button>
        </Toolbar>

        <div>
          <FormControl sx={{ m: 1, minWidth: 500 }}>
            <InputLabel id="demo-simple-select-label">Customer</InputLabel>
            <Select
              disabled={props.isReadOnly}
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              label="Language"
              onChange={handleClientSelect}
              value={isNull(customer) ? "" : customer.id}
            >
              {customers &&
                customers.map((data) => {
                  return (
                    <MenuItem value={data.id}>
                      {data.companyDisplayName}
                    </MenuItem>
                  );
                })}
            </Select>
          </FormControl>
          {customer && (
            <div className="info">
              <p>Payment Address:</p>
              <p>{customer.address}</p>
              <p>{customer.city}</p>
              <p>{customer.zipCode}</p>
              <p>{customer.country}</p>
            </div>
          )}
        </div>

        <div>
          <Divider sx={{ m: 1 }} />
          <TextField
            sx={{ m: 1, minWidth: 200 }}
            id="full-width-text-field"
            label="Quote Number"
            value={quoteNumber}
            onChange={(e) => setQuoteNumber(e.target.value)}
            InputLabelProps={{
              shrink: true,
            }}
          />
          <TextField
            sx={{ m: 1, minWidth: 200 }}
            id="outlined-required"
            label="Quote Date"
            defaultValue="1-1-1"
            type="date"
            value={quoteDate || ""}
            onChange={(e) => setQuoteDate(e.target.value)}
            InputLabelProps={{
              shrink: true,
            }}
          />

          <TextField
            sx={{ m: 1, minWidth: 200 }}
            id="date"
            label="Due Date"
            type="date"
            value={dueDate}
            onChange={(e) => setDueDate(e.target.value)}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </div>

        <div>
          <Divider sx={{ m: 1 }} />
          <TextField
            sx={{ m: 1, minWidth: 200 }}
            id="full-width-text-field"
            label="Seller"
            value={user.firstName + " " + user.lastName}
            InputLabelProps={{
              shrink: true,
            }}
          />
          <TextField
            sx={{ m: 1, minWidth: 200 }}
            id="outlined-required"
            label="Delivery Date"
            type="date"
            value={deliveryDate}
            onChange={(e) => setDeliveryDate(e.target.value)}
            InputLabelProps={{
              shrink: true,
            }}
          />
          <FormControl sx={{ m: 1, minWidth: 250 }}>
            <InputLabel
              id="demo-simple-select-label"
              shrink={true}
              style={{
                background: "white",
                paddingLeft: "4px",
                paddingRight: "4px",
              }}
            >
              Payment Method
            </InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              label="Payment Method"
              onChange={(event) => setPaymentMethod(event.target.value)}
              defaultValue={paymentMethod}
              value={paymentMethod}
            >
              {paymentMethods.map((key) => {
                return (
                  <MenuItem value={key}>{PaymentMethodEnum[key]}</MenuItem>
                );
              })}
            </Select>
          </FormControl>
          <TextField
            sx={{ m: 1, minWidth: 200 }}
            id="time"
            label="Quote Time"
            type="time"
            value={quoteTime}
            onChange={(e) => setQuoteTime(e.target.value)}
            InputLabelProps={{
              shrink: true,
            }}
          />
          <Divider sx={{ m: 1 }} />
        </div>
        <div>
          <QuoteItems
            items={items}
            quoteItems={quoteItems}
            handleItemAdded={handleItemAdded}
            handleCaluclationsChange={handleCaluclationsChange}
          />
        </div>
        <Paper
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "space-around",
            paddingBottom: "16px",
          }}
        >
          <TextField
            label="Notes"
            style={{ width: "40%" }}
            placeholder="MultiLine with rows: 2 and rowsMax: 4"
            value={notes}
            onChange={(e) => setNotes(e.target.value)}
            multiline
            rows={5}
            maxRows={10}
          />
          <TextField
            label="Terms and Conditions"
            style={{ width: "40%" }}
            placeholder="MultiLine with rows: 2 and rowsMax: 4"
            value={termsAndConditions}
            onChange={(e) => setTermsAndConditions(e.target.value)}
            multiline
            rows={5}
            maxRows={10}
          />
        </Paper>
      </TableContainer>
    </>
  );
};

const QuoteForm = (props) => {
  const authContext = useContext(AuthContext);
  const params = useParams();

  const [customers, setCustomers] = useState([]);
  const [items, setItems] = useState([]);
  const [quote, setQuote] = useState();

  const customersUrl = baseUrl + "/api/customer/all";

  const allCustomers = useApiRequest(customersUrl, "GET", authContext.token);

  useEffect(() => {
    setCustomers(allCustomers.data);
  }, [allCustomers]);

  const initQuoteUrl = isNull(params.quoteId)
    ? baseUrl + "/api/quote/init"
    : baseUrl + "/api/quote?id=" + params.quoteId;

  const initQuote = useApiRequest(initQuoteUrl, "GET", authContext.token);

  useEffect(() => {
    setQuote(initQuote.data);
  }, [initQuote]);

  const itemsUrl = baseUrl + "/api/item/all";

  const itemsResult = useApiRequest(itemsUrl, "GET", authContext.token);

  useEffect(() => {
    setItems(itemsResult.data);
  }, [itemsResult]);

  return (
    <div>
      <FormContent quote={quote} customers={customers} items={items}/>
    </div>
  );
};

export default QuoteForm;
