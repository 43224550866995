import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import React, { useState, useEffect } from "react";
import "./QuoteItemsTotal.css";
import { Table } from "@mui/material";
import { isNull, roundToTwo } from "../../utils/Utils";

const QuoteItemsTotal = (props) => {
  const [subtotal, setSubtotal] = useState(0);
  const [subtotalLcy, setSubtotalLcy] = useState(0);
  const [subtotalHrk, setSubtotalHrk] = useState(0);
  const [tax, setTax] = useState(0);
  const [taxLcy, setTaxLcy] = useState(0);
  const [taxHrk, setTaxHrk] = useState(0);
  const [total, setTotal] = useState(0);
  const [totalLcy, setTotalLcy] = useState(0);
  const [totalHrk, setTotalHrk] = useState(0);

  const getCalculations = () => {
    return {
      subtotal: subtotal,
      subtotalLcy: subtotalLcy,
      subtotalHrk: subtotalHrk,
      tax: tax,
      taxLcy: taxLcy,
      taxHrk: taxHrk,
      total: total,
      totalLcy: totalLcy,
      totalHrk: totalHrk,
    };
  };

  useEffect(() => {
    if (!isNull(props.items)) {
      calculateSubtotalLcy(props.items);
      calculateSubtotal(props.items);
      calculateSubtotalHrk(props.items);
      calculateTax(props.items);
      calculateTaxLcy(props.items);
      calculateTaxHrk(props.items);
      setTotal((prev) => {
        return calculateSubtotal(props.items) + calculateTax(props.items);
      });
      setTotalLcy((prev) => {
        return calculateSubtotalLcy(props.items) + calculateTaxLcy(props.items);
      });
      setTotalHrk((prev) => {
        return calculateSubtotalHrk(props.items) + calculateTaxHrk(props.items);
      });
    } else {
      setSubtotal(0);
      setSubtotalLcy(0);
      setSubtotalHrk(0);
      setTax(0);
      setTaxLcy(0);
      setTaxHrk(0);
      setTotal(0);
      setTotalLcy(0);
      setTotalHrk(0);
    }
  }, [props.items]);

  useEffect(() => {
    props.handleCaluclationsChange(getCalculations());
  }, [
    subtotal,
    subtotalHrk,
    subtotalLcy,
    tax,
    taxHrk,
    taxLcy,
    total,
    totalHrk,
    totalLcy,
  ]);

  const calculateSubtotal = (items) => {
    let sum = 0;
    if (!isNull(items)) {
      items.forEach((element) => {
        sum += element.totalItemCurrency;
      });
    }
    setSubtotal((prev) => {
      return sum;
    });
    return sum;
  };

  const calculateSubtotalLcy = (items) => {
    let sum = 0;
    if (!isNull(items)) {
      items.forEach((element) => {
        sum += element.totalLocalCurrency;
      });
    }
    setSubtotalLcy((prev) => {
      return sum;
    });
    return sum;
  };

  const calculateSubtotalHrk = (items) => {
    let sum = 0;
    if (!isNull(items)) {
      items.forEach((element) => {
        sum += element.totalHrk;
      });
    }
    setSubtotalHrk((prev) => {
      return sum;
    });
    return sum;
  };

  const calculateTax = (items) => {
    let sum = 0;
    if (!isNull(items)) {
      items.forEach((element) => {
        sum += element.taxItemCurrency;
      });
    }
    setTax((prev) => {
      return sum;
    });
    return sum;
  };

  const calculateTaxLcy = (items) => {
    let sum = 0;
    if (!isNull(items)) {
      items.forEach((element) => {
        sum += element.taxLocalCurrency;
      });
    }
    setTaxLcy((prev) => {
      return sum;
    });
    return sum;
  };

  const calculateTaxHrk = (items) => {
    let sum = 0;
    if (!isNull(items)) {
      items.forEach((element) => {
        sum += element.taxHrk;
      });
    }
    setTaxHrk((prev) => {
      return sum;
    });
    return sum;
  };

  return (
    <Paper
      style={{
        boxShadow: "0px 13px 20px 0px #80808029",
        display: "flex",
        flexDirection: "row-reverse",
      }}
    >
      <Table style={{ width: "33%" }} className="total">
        <TableRow>
          <TableCell style={{ border: 0 }}>
            <h4>Subtotal</h4>
          </TableCell>
          <TableCell style={{ border: 0 }}>
            {roundToTwo(subtotal)} EUR
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell style={{ border: 0 }}>
            <h4>Tax</h4>
          </TableCell>
          <TableCell style={{ border: 0 }}>
            {roundToTwo(tax)} EUR
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell style={{ border: 0 }}>
            <h4>Total</h4>
          </TableCell>
          <TableCell style={{ border: 0 }}>
            {roundToTwo(total)} EUR
          </TableCell>
        </TableRow>
      </Table>
    </Paper>
  );
};

export default QuoteItemsTotal;
