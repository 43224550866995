import React, { useContext, useState, useEffect } from "react";
import AuthContext from "../../store/auth-context";
import "./QuotePage.css"
import { baseUrl } from "../../Config";
import { TextField } from "@mui/material";
import { isNull } from "../../utils/Utils";
import { SendGetRequest } from "../../actions/Actions";
import QuotesTable from "../Table/QuotesTable";

const QuotesPage = () => {
  const [quotes, setQuotes] = useState([]);
  const [year, setYear] = useState(new Date().getFullYear());

  const authContext = useContext(AuthContext);

  const url = baseUrl + "/api/quote/all?year=" + year;

  useEffect(() => {
    SendGetRequest(url, authContext.token, quotesCallback);
  }, []);

  useEffect(() => {
    SendGetRequest(url, authContext.token, quotesCallback);
  }, [year]);

  const quotesCallback = (data) => {
    setQuotes(data);
  }

  const refreshHandler = () => {
    SendGetRequest(url, authContext.token, quotesCallback);
  }

  return (
    <div className="InvoicesPage">
      <TextField
                value={isNull(year) ? "" : year}
                type="number"
                onChange={(e) => setYear(e.target.value)}
                label="Year"
                InputLabelProps={{
                  shrink: true,
                }}
              />
      <QuotesTable data={quotes} refreshHandler={refreshHandler}/>
    </div>
  );
};

export default QuotesPage;
