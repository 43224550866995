import React from "react";

import { formatBarcodePrice } from "../../utils/Utils";
import PDF417 from "pdf417-generator";

const QuoteBarcode = (props) => {
  const companyInfo = JSON.parse(sessionStorage.getItem("companyInfo"));
  const quote = props.quote;
  const code = `HRVHUB30
                EUR
                ${formatBarcodePrice(quote.total)}
                ${quote.customer.companyDisplayName}
                ${quote.customer.address}
                ${quote.customer.zipCode + " " + quote.customer.city}
                ${companyInfo.companyName}
                ${companyInfo.address}
                ${companyInfo.zipCode + " " + companyInfo.city}
                ${companyInfo.iban}
                HR00
                ${quote.quoteNumber}
                GDSV
                Uplata po ponudi ${quote.quoteNumber}`;

    let canvas = document.createElement('canvas');
    PDF417.draw(code, canvas, 4, -1, 3);


    return <img src={canvas.toDataURL()} alt="Kod" style={{width: "100%"}}/>
};

export default QuoteBarcode;
